// config.js
const config = {
    // apiUrl: 'http://localhost:3001',
    apiUrl: 'https://pro-kargo.kz:3001',
    
    nameCargo: 'Pro Kargo'
  };
  
  
export default config;
  